@font-face {
  font-family: "Sunflower";
  src: url("./webpages/assets/font/Sunflower.otf") format("truetype");
  font-weight: 200 !important;
}

@font-face {
  font-family: "JostMedium";
  src: url("./webpages/assets/font/Jost-Medium.ttf") format("truetype");
  font-weight: 200 !important;
}



@font-face {
  font-family: "ZohoPuviBold";
  src: url("./webpages/assets/font/Zoho Puvi Bold.otf") format("truetype");
  font-weight: 200 !important;
}

@font-face {
  font-family: "ZohoPuviRegular";
  src: url("./webpages/assets/font/Zoho Puvi Regular.otf") format("truetype");
  font-weight: 200 !important;
}

@font-face {
  font-family: "ZohoPuviSemibold";
  src: url("./webpages/assets/font/Zoho Puvi Semibold.otf") format("truetype");
  font-weight: 200 !important;
}

@font-face {
  font-family: "FiraSans";
  src: url("./webpages/assets/font/FiraSans-Medium.ttf");
  font-weight: bold !important;
}



body {
  margin: 0px !important;
  height: 100vh !important;
  width: 100%;
  scroll-behavior: smooth;
  font-size: 14px;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #ffffff;
  padding: 10px 20px;
}

/* admin */
.linktagadmin {
  text-decoration: none;
  color: white !important;
  font-size: 20px;
}

.linktagadmin:hover {
  background: transparent !important;
}

@media only screen and (max-width: 500px) and (min-width: 100px) {
  .linktagadmin {
    font-size: 15px;
  }
}

.linkbtn {
  text-decoration: none;
  color: white !important;
}

.linkbtn:hover {
  background: transparent !important;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;

}

nav {
  margin-top: -15px;
}

.nav-arearight {
  display: flex;
  gap: 10px;
  /* flex-direction: row-reverse !important;  */
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: auto;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  margin-bottom: 0px;
}

.menu-items1 {
  margin-top: 0px !important;
  position: relative;
  font-size: 16px;
  color: #ffffff;
  font-family: 'FedraSansStd-medium', sans-serif;
}

.menu-items1:last-child {
  border-right: none !important;
}

.menu-items1 a {
  display: block;
  font-size: inherit;
  color: #cf4520;
  text-decoration: none;
  word-wrap: break-word;

}

/* a:hover {
  display: block;
  background-color: #dbd4c6 !important;
  font-size: inherit;
  color: #cf4520;
  text-decoration: none;
  word-wrap: break-word;
} */

.dropdown.show button {
  display: flex;
  align-items: center;
  color: black;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items1>a,
.menu-items1 button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items1 button {
  background-color: transparent;
  border: none;
  display: flex;
  color: #dbd4c6;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.menu-items1 a:hover,
.menu-items1 button:hover {
  background-color: transparent;
  color: #cf4520;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
}

.dropdown {
  position: absolute;
  right: 0;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 14rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1500px !important;
  margin: 0 auto;
  padding: 2rem 20px 0rem 20px;
}

.contents {
  max-width: 1500px !important;
  margin: 0 auto;
  /* padding: 2rem 20px 0rem 20px; */
}

.content h2 {
  margin-bottom: 1rem;
}
/*payment animation*/

.pulsating-circle {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}


/* .content a {
  color: #cc3852;
  margin-right: 10px;
} */

@media only screen and (max-width: 1000px) {
  .content {
    margin: 0 auto;
    padding: 2rem 0 0 0;
  }
}

/* footer */
footer {
  bottom: 0 !important;
  height: auto;
  position: sticky;
}

.footerlink {
  font-family: 'JostMedium' !important;
  text-decoration: none !important;
  color: #aea493;
  font-size: 14px;
  line-height: 1.21;
}

.footerlink:hover {
  font-family: 'JostMedium' !important;
  background-color: transparent !important;
  text-decoration: none !important;
  color: #cf4520 !important;
  font-size: 14px;
}

.footerlinktamil {
  font-family: "JostMedium" !important;
  text-decoration: none !important;
  color: #AEA493;
  font-size: 12px;
  line-height: 1.21;
}

.footerlinktamil:hover {
  font-family: 'JostMedium' !important;
  text-decoration: none !important;
  color: #cf4520 !important;
  font-size: 12px;
  line-height: 1.21;
}

.emailstyle {
  --c: #aea493;
  /* the color  */
  --h: 1.2em;
  /* the height */

  line-height: var(--h);
  background:
    linear-gradient(var(--c) 0 0) no-repeat calc(200% - var(--_p, 0%)) 100%/200% var(--_p, .08em);
  color: #cf4520;
  overflow: hidden;
  text-shadow:
    0 calc(-1*var(--_t, 0em)) var(--c),
    0 calc(var(--h) - var(--_t, 0em)) #cf4520;
  transition: .3s var(--_s, 0s), background-position .3s calc(.3s - var(--_s, 0s));
}

.emailstyle:hover {
  --_t: var(--h);
  --_p: 100%;
  --_s: .3s
}

.footerbaner {
  max-height: 96px;
  height: 96px;
  width: 100%;
  -webkit-background-size: clip;
  background-size: clip;
  object-fit: cover;
  -webkit-background-position: 50%;
  background-position: 50%;
}

.footerimg {
  transform: rotate(90deg);
  margin-top: 120px;
}

@media only screen and (max-width: 900px) {
  .footerimg {
    display: none;
  }

  .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    border-right: none !important;
    margin-bottom: 0px !important;
  }
}

/* Pages */
.carousel-wrapper {
  display: flex;
  justify-content: center;
  max-width: 1500px;
  height: 500px;
}

.carousel-wrappermedia {
  padding-top: 5px;
  background-color: #e9e9e9 !important;
  height: 516px;
}

.carousel .thumbs-wrapper {
  display: none;
}

.zoom-image-container {
  max-width: 100%;
  height: auto;
  overflow: hidden !important;
  box-shadow: 0 18px 16px -5px rgb(0 0 0 / 30%);
  background: rgb(0 0 0 / 30%);
}

.zoom-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
  overflow: hidden;
}

.zoom-image:hover {
  transform: scale(1.1);
}

.zoom-image-textdiv {
  position: relative;
  background-color: rgba(45, 45, 45, 0.31);
  text-align: center;
  bottom: 97px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 45px;
  color: rgb(250, 247, 240);
  padding-top: 13px;
  display: flex;
  height: 90px;
}

.zoom-image-textdiv2 {
  position: relative;
  background-color: rgba(116, 70, 37, 0.31);
  text-align: center;
  bottom: 104px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 45px;
  color: rgb(250, 247, 240);
  padding-top: 25px;
  display: flex;
  height: 97px;
}

.zoom-image-textdiv3 {
  position: relative;
  background-color: rgba(62, 41, 32, 0.31);
  text-align: center;
  bottom: 95px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 45px;
  color: rgb(250, 247, 240);
  padding-top: 13px;
  display: flex;
  height: 90px;
}

.arrowimage {
  margin-left: 8.1%;
  width: 15px;
  filter: brightness(100);
  transform: scale(1);
  transition: all 0.3s ease-in-out 0s;
}

/* tamil */
.zoom-image-textdivtamil {
  position: relative;
  background-color: rgba(45, 45, 45, 0.31);
  text-align: center;
  bottom: 97px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 25px;
  color: rgb(250, 247, 240);
  padding-top: 13px;
  display: flex;
  height: 90px;
}

.zoom-image-textdiv2tamil {
  position: relative;
  background-color: rgba(116, 70, 37, 0.31);
  text-align: center;
  bottom: 104px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 25px;
  color: rgb(250, 247, 240);
  padding-top: 25px;
  display: flex;
  height: 97px;
}

.zoom-image-textdiv3tamil {
  position: relative;
  background-color: rgba(62, 41, 32, 0.31);
  text-align: center;
  bottom: 95px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 25px;
  color: rgb(250, 247, 240);
  padding-top: 13px;
  display: flex;
  height: 90px;
}

.knowmorebtn {
  text-decoration: none;
  color: white !important;
}

.knowmorebtn:hover {
  background: transparent !important;
  color: black !important;
}

@media only screen and (max-width: 998px) and (min-width: 859px) {

  .carousel-wrapper {
    display: flex;
    justify-content: center;
    height: 405px;
  }

  .zoom-image-textdiv {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 89px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 33px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 85px;
  }

  .zoom-image-textdiv2 {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 99px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 33px;
    color: rgb(250, 247, 240);
    padding-top: 21px;
    display: flex;
    height: 95px;
  }

  .zoom-image-textdiv3 {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 33px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdivtamil {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 89px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 35px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 85px;
  }

  .zoom-image-textdiv2tamil {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 99px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 35px;
    color: rgb(250, 247, 240);
    padding-top: 21px;
    display: flex;
    height: 95px;
  }

  .zoom-image-textdiv3tamil {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 35px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 80px;
  }
}


@media only screen and (max-width: 900px) and (min-width: 790px) {

  .carousel-wrapper {
    display: flex;
    justify-content: center;
    height: 325px;
  }

  .zoom-image-textdiv {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 89px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 35px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 85px;
  }

  .zoom-image-textdiv2 {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 99px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 35px;
    color: rgb(250, 247, 240);
    padding-top: 21px;
    display: flex;
    height: 95px;
  }

  .zoom-image-textdiv3 {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 35px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdivtamil {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 89px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 33px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 85px;
  }

  .zoom-image-textdiv2tamil {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 99px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 33px;
    color: rgb(250, 247, 240);
    padding-top: 21px;
    display: flex;
    height: 95px;
  }

  .zoom-image-textdiv3tamil {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 33px;
    color: rgb(250, 247, 240);
    padding-top: 8px;
    display: flex;
    height: 80px;
  }
}

@media only screen and (max-width: 791px) and (min-width: 700px) {

  .carousel-wrapper {
    display: flex;
    justify-content: center;
    height: 270px;
  }

  .zoom-image-textdiv {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 89px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 28px;
    color: rgb(250, 247, 240);
    padding-top: 10px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdiv2 {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 96px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 28px;
    color: rgb(250, 247, 240);
    padding-top: 21px;
    display: flex;
    height: 90px;
  }

  .zoom-image-textdiv3 {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 28px;
    color: rgb(250, 247, 240);
    padding-top: 10px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdivtamil {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 89px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 27px;
    color: rgb(250, 247, 240);
    padding-top: 10px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdiv2tamil {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 96px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 27px;
    color: rgb(250, 247, 240);
    padding-top: 21px;
    display: flex;
    height: 90px;
  }

  .zoom-image-textdiv3tamil {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 27px;
    color: rgb(250, 247, 240);
    padding-top: 10px;
    display: flex;
    height: 80px;
  }
}

@media only screen and (max-width: 701px) and (min-width: 597px) {

  .carousel-wrapper {
    display: flex;
    justify-content: center;
    height: 255px;
  }

  .zoom-image-textdiv {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 28px;
    color: rgb(250, 247, 240);
    padding-top: 17px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdiv2 {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 87px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 28px;
    color: rgb(250, 247, 240);
    padding-top: 25px;
    padding-left: 10px;
    padding-bottom: 10px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdiv3 {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 28px;
    color: rgb(250, 247, 240);
    padding-top: 5px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdivtamil {
    position: relative;
    background-color: rgba(45, 45, 45, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 25px;
    color: rgb(250, 247, 240);
    padding-top: 17px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdiv2tamil {
    position: relative;
    background-color: rgba(116, 70, 37, 0.31);
    text-align: center;
    bottom: 87px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 25px;
    color: rgb(250, 247, 240);
    padding-top: 25px;
    padding-left: 0px;
    padding-bottom: 10px;
    display: flex;
    height: 80px;
  }

  .zoom-image-textdiv3tamil {
    position: relative;
    background-color: rgba(62, 41, 32, 0.31);
    text-align: center;
    bottom: 85px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: 'FedraSansStd-A-medium', serif;
    font-size: 25px;
    color: rgb(250, 247, 240);
    padding-top: 5px;
    display: flex;
    height: 80px;
  }
}

.zoom-image-textdivmedia {
  /* position: relative;
  background-color: rgba(45, 45, 45, 0.31);
  text-align: center;
  bottom: 110px;
  padding-top: 18px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  width: 100%; */
  position: relative;
  background-color: rgba(45, 45, 45, 0.31);
  text-align: center;
  bottom: 100px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 28px;
  color: rgb(250, 247, 240);
  padding-top: 0px;
  display: flex;
}

.zoom-image-textdiv2media {
  /* position: relative;
  background-color: rgba(116, 70, 37, 0.31);
  bottom: 110px;
  text-align: center;
  padding-top: 18px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
  overflow: hidden; */
  position: relative;
  background-color: rgba(116, 70, 37, 0.31);
  text-align: center;
  bottom: 100px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 28px;
  color: rgb(250, 247, 240);
  padding-top: 0px;
  display: flex;
}

.zoom-image-textdiv3media {
  /* position: relative;
  background-color: rgba(62, 41, 32, 0.31);
  bottom: 110px;
  text-align: center;
  padding-top: 18px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden; */
  position: relative;
  background-color: rgba(62, 41, 32, 0.31);
  text-align: center;
  bottom: 100px;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
  font-family: 'FedraSansStd-A-medium', serif;
  font-size: 28px;
  color: rgb(250, 247, 240);
  padding-top: 0px;
  display: flex;
}

.arrowimagemedia {
  margin-left: 7.1%;
  width: 15px;
  filter: brightness(100);
  transform: scale(1);
  transition: all 0.3s ease-in-out 0s;
}

.tryimage {
  clip-path: polygon(60% 0, 0% 500%, 0% 0%, 0 0%);
}

.linktag {
  color: #cf4520;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
  padding-top: 13px;
}

.linktag:hover {
  background-color: transparent !important;
  color: #cf4520;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
  padding-top: 13px;
}

.linktaglogin {
  margin-left: 6px;
  color: #cc3852 !important;
  text-decoration: underline !important;
  font-size: 20px;
  font-weight: 600;
  font-family: FedraSansStd-book;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.linktaglogin:hover {
  margin-left: 6px;
  background-color: transparent !important;
  text-decoration: underline !important;
  color: #cc3852;
  font-size: 20px;
  font-weight: 600;
  font-family: FedraSansStd-book;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.linktaglogintamil {
  margin-left: 6px;
  color: #cc3852 !important;
  text-decoration: underline !important;
  font-size: 15px;
  font-weight: 600;
  font-family: FedraSansStd-book;
  /* border-bottom: 1px solid #ffbd31; */
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.linktaglogintamil:hover {
  margin-left: 6px;
  background-color: transparent !important;
  text-decoration: underline !important;
  color: #cc3852;
  font-size: 15px;
  font-weight: 600;
  font-family: FedraSansStd-book;
  /* border-bottom: 1px solid #ffbd31; */
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.linktaglogin1 {
  color: #cc3852 !important;
  text-decoration: underline !important;
  font-size: 18px;
  font-weight: 600;
  font-family: "PT Serif", serif;
}

.linktaglogin1:hover {
  background-color: transparent !important;
  text-decoration: underline !important;
  font-size: 18px;
  font-weight: 600;
  font-family: "PT Serif", serif;
}

.linktaglogin1tamil {
  color: #cc3852 !important;
  text-decoration: none !important;
  font-size: 15px;
  font-weight: 600;
  font-family: "PT Serif", serif;
  /* border-bottom: 1px solid #ffbd31; */
}

.linktaglogin1tamil:hover {
  background-color: transparent !important;
  text-decoration: none !important;
  font-size: 15px;
  font-weight: 600;
  font-family: "PT Serif", serif;
}

/* common link style */
.commonlinkstyle {
  background-color: transparent !important;
  text-decoration: none !important;
  color: rgb(40, 35, 30) !important;
  cursor: pointer;
}

.commonlinkstyle:hover {
  background-color: transparent !important;
  text-decoration: none !important;
  color: rgb(40, 35, 30);
  cursor: pointer;
}

/* common link style */
.innerlinkstyle {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #cf4520 !important;
  font-size: 17px;
  font-weight: 600;
}

.innerlinkstyle:hover {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #cf4520 !important;
  font-size: 17px;
  font-weight: 600;
}

.innterarrow {
  width: 25px;
  padding-left: 15px !important;
}

.innerlinkstylemedia {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #cf4520 !important;
  font-size: 15px;
  font-weight: 600;
}

.innerlinkstylemedia:hover {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #cf4520 !important;
  font-size: 15px;
  font-weight: 600;
}

.innterarrowmedia {
  width: 22px;
  padding-left: 16px !important;
}

.help-image-container {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden !important;
  opacity: 0.5;

}


.helpimgtext {
  color: black !important;
  text-align: center;
  align-items: center;
  padding: 50px;
  margin: auto !important;
}


/* Abi added css  start*/
.home-background {
  background: url('./webpages/assets/home/redbg.jpg') no-repeat !important;
  background-position: 0 -15% !important;
  width: 100% !important;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);
  margin: 30px 0 30px 0;
}


@media only screen and (max-width: 479px) and (min-width: 199px) {
  .helpimgtext {
    color: black !important;
    text-align: center;
    align-items: center;
    padding: 5px;
    margin: auto !important;
    width: 100%;
  }
}

/* .footerbg {
  background-image: url('./webpages/assets/home/relatedbg.png');
  height: 100% !important;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.5);
  padding-top: 10px;
} */

.fooLatest {
  float: left !important;
  width: 100%;
  font-family: 'FedraSansStd-A-medium', 'sans-serif' !important;
  font-size: 35px !important;
  line-height: 1.13 !important;
  font-weight: bold !important;
  text-align: center !important;
  color: #02026C !important;
  padding-top: 20px !important;
}


@media only screen and (max-width: 687px) and (min-width: 400px) {
  .fooLatest {
    float: left !important;
    width: 100%;
    font-family: 'FedraSansStd-A-medium', 'sans-serif' !important;
    font-size: 30px !important;
    line-height: 1.13 !important;
    font-weight: bold !important;
    text-align: center !important;
    color: #02026C !important;
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 600px) and (min-width: 340px) {
  .fooLatest {
    float: left !important;
    width: 100%;
    font-family: 'FedraSansStd-A-medium', 'sans-serif' !important;
    font-size: 25px !important;
    line-height: 1.13 !important;
    font-weight: bold !important;
    text-align: center !important;
    color: #02026C !important;
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 339px) and (min-width: 199px) {
  .fooLatest {
    float: left !important;
    width: 100%;
    font-family: 'FedraSansStd-A-medium', 'sans-serif' !important;
    font-size: 20px !important;
    line-height: 1.13 !important;
    font-weight: bold !important;
    text-align: center !important;
    color: #02026C !important;
    padding-top: 20px !important;
  }
}

/* Abi added css End*/

@media only screen and (max-width: 555px) and (min-width: 300px) {

  /* My changes Start----------------------------------------------------- */
  .zoom-image-textdiv2 {
    position: relative;
    background-color: rgba(255, 111, 0, 0.31);
    /* opacity: 0.5; */
    text-align: center;
    bottom: 96px;
    padding-top: 18px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
  }

  .zoom-image-textdiv3 {
    position: relative;
    background-color: rgba(255, 111, 0, 0.31);
    /* opacity: 0.5; */
    text-align: center;
    bottom: 96px;
    padding-top: 18px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
  }

  .zoom-image-textdiv {
    position: relative;
    background-color: rgba(255, 111, 0, 0.31);
    /* opacity: 0.5; */
    text-align: center;
    bottom: 96px;
    padding-top: 18px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    overflow: hidden;
  }

  /* My changes End----------------------------------------------------- */
  .zoom-image-text {
    font-size: 25px;
    text-shadow: 0 1px 2px rgba(212, 198, 198, 0.6);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .borderimage {
    max-width: 310px !important;
  }

  .headtext1 {
    font-size: 22px !important;
  }

  .headtext2 {
    font-size: 16px !important;
  }
}

.responsive-img {
  width: 1200px;
  height: 520px;
}

@media (max-width: 1200px) {
  .responsive-img {
    width: 900px;
    height: 433px;
  }
}

/* Small screens (landscape phones) */
@media (max-width: 992px) {
  .responsive-img {
    width: 700px;
    height: 347px;
  }
}

/* Extra small screens (portrait phones) */
@media (max-width: 768px) {
  .responsive-img {
    width: 600px;
    height: 260px;
  }
}

/* Extra extra small screens (small portrait phones) */
@media (max-width: 576px) {
  .responsive-img {
    width: 380px;
    height: 700px;
  }
}

/* Very small screens (extra small portrait phones) */
@media (max-width: 400px) {
  .responsive-img {
    width: 580px;
    height: 1420px;
  }
}